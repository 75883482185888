
@import '~www/themes/doctors/common/variables';

.the-header {
  position: relative;
  padding-top: 64px;
  background-color: $ui-kit-bg-gray-0;

  &__dialog {
    position: fixed;
    inset: 0;
    z-index: 201;
    background-color: $ui-kit-bg-gray-0;
  }
}
