
@import '~www/themes/doctors/common/variables';

.bottom-panel {
  box-sizing: border-box;

  &:not(&_no-height) {
    min-height: 56px;
  }

  &_mobile:not(&_no-height) {
    min-height: 72px;
  }

  &__content {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    box-sizing: inherit;
    min-height: inherit;
    background-color: $ui-kit-bg-gray-0;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.1),
      0 4px 5px 0 rgba(0, 0, 0, 0.07),
      0 1px 10px 0 rgba(0, 0, 0, 0.06);
  }

  &__container {
    width: 100%;
    box-sizing: inherit;
  }
}
