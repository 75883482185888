
@import '~www/themes/doctors/common/variables';

.modal {
  background-color: white;
  min-height: 100%;

  &_limit-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &_has-footer {
    padding-bottom: 56px;
  }

  &__header {
    flex: 0 0 56px;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    background-color: white;
  }

  &__header-append {
    flex: 0 0 auto;
    margin: 0 0 8px;
    padding: 0 8px;
  }

  &__header-button {
    display: flex;  // нужно, чтобы иконка не съезжала при большом родительском 'font-size'
    width: 24px;
    height: 24px;
    margin: 0 32px 0 0;
    cursor: pointer;
  }

  &__header-divider_hidden {
    visibility: hidden;
  }

  &__body {
    flex: 1 0 0;
    overflow-y: auto;
  }

  &__body_flex-basis-auto {
    flex-basis: auto;
  }

  &__body_has-fixed-btn {
    // -1px от 72px, чтобы убрать нижнюю границу последнего элемента - (на margin не изменять - баг на iphone)
    padding-bottom: 71px;
    box-sizing: border-box;
  }

  &__body_no-overflow-x {
    overflow-x: hidden;
  }

  &__body_no-overflow-y {
    overflow-y: hidden;
  }

  &__body_height-inherit {
    height: inherit;
  }

  &__footer {
    height: 56px;
    padding: 0 8px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    @include elevation-8;
  }
}
