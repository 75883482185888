// не во всех компонентах можно использовать _reset.scss из vuetify,
// т.к. он может ломать много наших стилей
@mixin vuetify-custom-reset {
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  button {
    border-style: none;
  }

  hr {
    margin: 0;
  }
}
