
@import '~www/themes/doctors/common/variables';

.search-dialog {
  &,
  &__card,
  &__input,
  &__input-container {
    height: 100%;
  }
}
