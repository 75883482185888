
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

#app[data-draft-reminder] {
  @include v-app-reset;

  .draft-reminder {
    position: fixed;
    bottom: 0;
    z-index: 201;
    background-color: $ui-kit-bg-gray-0;

    &_desktop {
      left: 50%;
      transform: translateX(-50%);

      .draft-reminder_text {
        width: 80%;
      }
    }

    &__avatar {
      width: 60px;
      height: 80px;
      object-fit: contain;
      border-radius: $border-radius-sm;
    }

    &__avatar_lpu {
      height: 60px;
    }
  }
}
