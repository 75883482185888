@import '~www/themes/doctors/common/variables';

.b-hint {
  width: 100%;
  display: block;
  box-sizing: border-box;
  border-radius: $border-radius-sm;
  background-color: $ui-kit-bg-gray-0;

  &_mobile {
    max-width: calc(100% - 16px);

    @media (max-width: 355px) { // фикс бага, чтобы не появлялось горизонтального скролла при разрешении меньше 355
      width: 314px;
    }
  }

  &__layer {
    z-index: 2;
    position: relative;
    box-sizing: inherit;
    border-radius: inherit;
    border: 1px solid $color-ultra-deep-grey;
    box-shadow: 0 0 8px rgba(151, 158, 167, 0.25);
  }

  &__background {
    z-index: 1;
    padding: 16px;
    position: relative;
    box-sizing: inherit;
    border-radius: $border-radius-sm;
    background-color: $ui-kit-bg-gray-0;
  }

  &__content {
    box-sizing: inherit;
  }
}
