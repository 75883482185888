@import '~www/themes/doctors/common/variables';

.cookie-banner {
  z-index: 5;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: $border-radius-md;
  background-color: $ui-kit-bg-primary;

  @include elevation-6;

  &_mobile {
    left: initial;
    bottom: initial;
    position: initial;
    transform: initial;
    box-shadow: initial;
    border-radius: initial;
  }
}
