
@import '~www/themes/doctors/common/variables';

.search-results {
  &__image-wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: $border-radius-md;
  }

  &__image {
    border: 1px solid $ui-kit-bg-gray-60;
    border-radius: $border-radius-md;
    object-fit: contain;
  }

  &__skeletons {
    :deep(.v-skeleton-loader__avatar) {
      width: 16px;
      height: 16px;
    }
  }

  &__result-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-decoration: none;

    &:hover,
    &_active {
      background-color: $ui-kit-bg-gray-40;
    }
  }
}
