
@import '~www/themes/doctors/common/variables';

.club-card-info {
  &__card-container {
    display: flex;

    &::before {
      content: '';
      display: block;
      padding-top: 145%;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 360px;
    padding: 16px 24px 8px;
    text-align: center;
    border-radius: $border-radius-md;
    background-color: $ui-kit-error;
    background-image: $club-coral-double-radial-gradient;
  }
}
