
@import '~www/themes/doctors/common/variables';

.base-search {
  display: flex;
  position: relative;

  &_desktop &__button {
    margin-left: 16px;
    flex: 0 0 56px;
  }

  &_desktop .search-site__input-container {
    position: relative;
  }

  .search-site__input-container {
    flex-grow: 1;
  }

  .search-site-dropdown {
    z-index: 2;
    padding: 24px 0;
    position: absolute;
    border-radius: 5px;
    background: $ui-kit-bg-gray-0;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    top: 100%;
    font-size: $font-size-body-1;
    color: $color-neur-dark;
    user-select: none;

    @include elevation-6;

    &__item {
      padding: 0 24px;
    }

    &__preview-text {
      padding: 0 24px;
    }
  }
}
