
@import '~www/themes/doctors/common/variables';

.search-bar {
  &__container {
    position: relative;
    display: flex;
    padding: 12px;
    margin: -12px;
    border-radius: $border-radius-md;
    background-color: $ui-kit-bg-gray-0;
  }

  &__input {
    flex-grow: 1;
  }

  &__button {
    height: 56px;
    background-color: $ui-kit-secondary;
  }

  &__dropdown-wrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 88px;
    border-radius: $border-radius-md;
  }

  &__dropdown {
    overflow: auto;
    max-height: 400px;
  }
}
