
@import '~www/themes/doctors/common/variables';

.text-field {
  $block: &;

  display: flex;

  &_gray {
    #{$block}__body {
      background-color: $ui-kit-bg-gray-40;
      border-radius: $border-radius-sm;
    }

    #{$block}__input::placeholder {
      color: $ui-kit-text-info;
    }

    #{$block}__fieldset {
      border: none;
    }
  }

  &__body {
    height: 56px;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
  }

  &__fieldset {
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
    min-inline-size: min-content;
    padding: 0;
    position: absolute;
    inset: 0;
    border-radius: $border-radius-sm;
    border: 1px solid $ui-kit-text-info;
    pointer-events: none;

    &_no-top {
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    &_no-right {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &_no-bottom {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &_no-left {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:hover &__fieldset {
    border-color: $ui-kit-text;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &_active &__fieldset,
  &_active:hover &__fieldset {
    border-width: 2px;
    border-color: $ui-kit-primary;
  }

  &__input-slot {
    padding: 0 16px;
    flex: 1 1 auto;
  }

  &__input {
    background-color: transparent;
    border-style: none;
    border-radius: 0;
    outline: none;
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }

  &_active &__input {
    caret-color: $ui-kit-primary;
  }
}
